import React, { useEffect } from "react"
import { graphql } from "gatsby"
import parse from "html-react-parser"
import "aos/dist/aos.css"
//import { parse } from "tldts"

/* Indie Components */
import Layout from "../components/Layout"
import Seo from "../components/SEO"
import MountainHeader from "../components/MountainHeader"
import PageWrapper from "../components/PageWrapper"
import PageTitleSection from "../components/PageTitleSection"
import Section from "../components/Section"
import SectionTitle from "../components/SectionTitle"
import BodyText from "../components/BodyText"
import Columns from "../components/Columns"
import Column from "../components/Column"
import FeaturedProject from "../components/FeaturedProject"
import IndieLink from "../components/IndieLink"

const PrivacyPolicyPage = ({ data: { page } }) => {
  useEffect(() => {
    const offset = window.innerWidth > 500 ? 360 : 180
    const AOS = require("aos")
    AOS.init({
      duration: 700,
      offset: offset,
      disable: function () {
        return /bot|googlebot|crawler|spider|robot|crawling/i.test(
          navigator.userAgent
        )
      },
    })

    if (AOS) {
      AOS.refresh()
    }
  }, [])

  const {
    seo,
    pageSettings: { showFooter },
    headerSettings: { headerImages, title, subTitle },
  } = page

  return (
    <Layout showMenu={false} showFooter={showFooter} page={page}>
      <Seo seo={seo} />
      <MountainHeader headerImages={headerImages} />
      <PageWrapper additionalClassName="home">
        <PageTitleSection title="Privacy Policy" subtitle="" />

        <Section dataAos="fade-up" additionalClasses="double-top-padding">
          <Columns mobile={true}>
            <Column size={{ all: 9 }}>
              <BodyText
                text=""
                weight="bold"
                padding={true}
                size="is-size-5 is-size-3-mobile"
              />
              <div class="container px-4 mx-auto max-w-2xl">
                <h2 class="text-2xl mb-1 leading-tight font-bold font-heading mx-auto max-w-2xl text-left text-dark is-size-5 is-size-3-mobile">
                  Privacy Policy
                </h2>
                <h3 class="text-xl font-bold mt-10">
                  Website Privacy Notice and Cookie Policy
                </h3>
                <p>
                  Indie Ridge and our affiliated companies worldwide (“Indie
                  Ridge”) are a global professional services firm focused on
                  delivering digital transformation for our clients. Indie Ridge
                  respects you and we are committed to honouring and protecting
                  your privacy. The reason we collect your personal data is to
                  improve our services to you. This Website Privacy Notice
                  describes our approach to privacy and our practises regarding
                  why we collect your personal data, what data we are
                  collecting, how we process it and how you can manage and
                  delete your personal data. If you have any questions or
                  concerns about this Website Privacy Notice or your personal
                  data, please contact us at data.hello@indieridge.com. Where we
                  determine the purpose and means of processing personal data,
                  Indie Ridge will be a controller. Where we act as a processor
                  in our capacity of providing services to a customer, we will
                  only process personal data in accordance with the instructions
                  of the customer. The customer will be the data controller of
                  that personal data and will be responsible to data subjects
                  for the way in which their personal data is processed. Where
                  applicable, in accordance with data protection laws we shall
                  assist the data controller in electing data subject rights.
                </p>
                <p class="font-bold my-8">
                  What Personal Data We Collect and How We Collect It
                </p>
                <p>
                  Indie Ridge, may at times collect your personal data in one of
                  the following ways:
                  <ul class="list-disc list-inside">
                    <li>
                      By entering information such as your name, and email
                      address, country of residence, company you represent,
                      industry and details of an inquiry on our Contact page as
                      well as other forms throughout our website, you enable us
                      to respond to a general/ business inquiry made by you or
                      on behalf of the company that you represent. If you wish
                      to be contacted by telephone, we may also collect your
                      phone number.
                    </li>
                    <li>
                      Indie Ridge use cookies and web beacons to collect data on
                      how you use our websites. This may include for example
                      information with respect to what Indie Ridge websites you
                      have visited, how long you stayed on them, which items you
                      clicked on and your IP-address. To know more about our use
                      of cookies, please see our Cookie Policy below.
                    </li>
                    <li>
                      E-mail Communications: If you are a recipient of our
                      marketing communications, we may track whenever you
                      receive, open, click a link, or share an email you receive
                      from Indie Ridge. Please visit our customer privacy notice
                      for further details. To unsubscribe from Hedgehog lab
                      marketing communications, please contact us a
                      hello@indieridge.com.
                    </li>
                    <li>
                      Information from Other Sources: Indie Ridge might receive
                      information about you from other sources, such as public
                      sources, social media, directories, registries to ensure
                      the accuracy and completeness of your personal data.
                    </li>
                  </ul>
                </p>
                <p class="font-bold my-8">
                  Purpose and Lawful Basis for Processing Personal Data
                </p>
                <p>
                  <ul class="list-disc list-inside">
                    <li>
                      With your consent. Where we require your consent, you will
                      find opt-in mechanisms and you have the ability to
                      withdraw consent at all times.
                    </li>
                    <li>
                      Where we need to perform the contract we are about to
                      enter into or have entered into with you. For example,
                      when we perform our services to you.
                    </li>
                    <li>
                      Where we need to comply with a legal or regulatory
                      obligation. This will include maintaining records,
                      compliance checks or screening and recording (e.g.
                      anti-money laundering, financial and credit checks, fraud
                      and crime prevention and detection, trade sanctions and
                      embargo laws). This may include automated checks of
                      personal data you provide about your identity against
                      relevant databases and contacting you to confirm your
                      identity or making records of our communications with you
                      for compliance purposes.
                    </li>
                    <li>
                      Where it is necessary for our legitimate interests or
                      those of a third party and your interests and fundamental
                      rights do not override those interests. Our legitimate
                      interest will include for example, market research
                      purposes, marketing purposes and appropriate controls to
                      ensure our website, processes and procedures are running
                      effectively for the prevention and detention of against
                      fraud and for IT security purposes. You can obtain further
                      information about how we assess our legitimate interests
                      against any potential impact on you in respect of specific
                      activities by contacting us.
                    </li>
                  </ul>
                </p>
                <p class="mt-4">
                  If processing of personal data is subject to any other laws,
                  then the lawful basis of processing personal data may be
                  different to that set out above and in those circumstances
                  will be based on national laws. Indie Ridge lab shall only
                  process the received information pursuant to pursue our
                  legitimate business interests for example screening CVs of
                  prospective candidates, establishing communication with
                  prospective customers (whom you represent) and personnel with
                  general/ business inquiries and to enhance our user
                  experience. You also have the option to subscribe/opt-in to
                  receive newsletters, thought leadership content and/or
                  marketing collateral. You can always opt-out of such options
                  using our subscription centre or write to
                  hello@indieridge.com. Indie Ridge shall adhere to your
                  preferences.
                </p>
                <p class="font-bold my-8">Security</p>
                <p>
                  Indie Ridge implements industry standard security measures to
                  keep your personal data secure and confidential, including but
                  not limited to:
                  <ul class="list-disc list-inside">
                    <li>
                      Restriction of access to your personal data to Indie Ridge
                      employees strictly on a need-to-know basis, such as
                      responding to an inquiry or request.
                    </li>
                    <li>
                      Implementation of physical, electronic, administrative,
                      technical, and procedural safeguards that comply with all
                      applicable rules, laws, legislation and regulations to
                      protect your personal data from unauthorised or
                      inappropriate access, alteration, disclosure and/or
                      destruction. It is important for you to protect against
                      unauthorised access to your password and to your computer.
                      Be sure to sign o when you are done using a shared
                      computer.
                    </li>
                    <li>
                      Indie Ridge employees who misuse personal data are subject
                      to strict disciplinary action, as it is a violation of the
                      Integrity Policy of Indie Ridge.
                    </li>
                  </ul>
                </p>
                <p class="font-bold my-8">
                  Who We May Share Your Personal Data With
                </p>
                <p>
                  <ul class="list-disc list-inside">
                    <li>
                      The personal data Indie Ridge collects from you is stored
                      in one or more databases hosted by third parties. These
                      third parties do not use or have access to your personal
                      data for any purpose other than cloud storage and
                      retrieval.
                    </li>
                    <li>
                      Where required or permitted by law, information may be
                      provided to others, such as but not limited to regulators,
                      under legal process, enforceable government directives.
                    </li>
                    <li>
                      From time to time, Indie Ridge may consider corporate
                      transactions such as a merger, acquisition,
                      reorganisation, asset sale, or similar. In these
                      instances, we may transfer or allow access to information
                      to enable the assessment and undertaking of that
                      transaction. If we buy or sell any business or assets,
                      personal data may be transferred to third parties involved
                      in the transaction. In these instances Indie Ridge will
                      continue to ensure your personal data is protected and
                      private, and Indie Ridge will give any impacted data
                      subjects notice before any personal data is transferred or
                      subject to a different privacy policy.
                    </li>
                  </ul>
                </p>
                <p class="mt-4">
                  We may also transfer personal data to our third-party
                  suppliers outside of the EU as described above. Where we
                  transfer personal data outside of the EU, we either transfer
                  personal data to countries that provide an adequate level of
                  protection as determined by the European Commission or we have
                  appropriate safeguards in place. Appropriate safeguards to
                  cover these transfers are in the form of standard
                  contractual/data protection clauses adopted by the European
                  Commission. Where we transfer personal data between our group
                  companies, we have covered these transfers by entering into
                  standard contractual clauses adopted by the European
                  Commission. If you would like more information on any of the
                  data transfer mechanisms on which we rely please contact our
                  Data Protection Officer, details available in the contact
                  section below. Data Storage and Retention Indie Ridge shall
                  retain your personal data pursuant to the business purposes.
                  We will only keep your personal data for as long as is
                  reasonably necessary taking into consideration our purposes
                  outlined above or to comply with legal, accounting or
                  reporting requirements under applicable law(s). To determine
                  the appropriate retention period for personal data, we
                  consider the amount, nature, and sensitivity of the personal
                  data, the potential risk of harm from unauthorised use or
                  disclosure of personal data, the purposes for which we process
                  the personal data and whether we can achieve those purposes
                  through other means, and the applicable legal requirements.
                  Personal data received from prospective customers shall be
                  retained for the duration of the prospective customer’s
                  business relationship with the Indie Ridge and in accordance
                  with the retention criteria set out in our customer privacy
                  notice. For more information on where and how long your
                  personal data is stored, and for more information on your
                  rights of erasure and portability, please contact us at
                  hello@indieridge.com
                </p>
                <h3 class="text-xl font-bold mt-10">1. Consent</h3>
                <p>
                  Indie Ridge privacy policy (‘Privacy Policy’) describes how we
                  (“we” or “us”) collect and use information in connection with
                  your access to and use of our website and any of its
                  subdomains, mobile applications, and related services
                  (collectively, the ‘Services’). By accessing and/or using any
                  of our Services, you accept and signify your informed consent
                  to the practices described in this Privacy Policy.
                </p>
                <h3 class="text-xl font-bold mt-10">2. Who We Are</h3>
                <p>
                  We are Indie Ridge Limited. We are a UK based technology
                  company with offices in the London, England. For the purposes
                  of the Data Protection Act (‘DPA’) and in-line with the EU
                  General Data Protection Regulation (the ‘GDPR’), Indie Ridge
                  is the ‘Data Controller’ and is responsible for your personal
                  data. If you have any concerns about the way we use your
                  information or any questions about this Privacy Policy, please
                  let us know. We can be contacted via email at
                  hello@indieridge.com or you can write to us at Indie Ridge
                  Limited 167-169 Great Portland Street 5th Floor London W1W 5PF
                </p>
                <h3 class="text-xl font-bold mt-10">
                  3. Information we collect from you
                </h3>
                <p>
                  We may collect and process the following aspects of data about
                  you: Information that you provide us– this is information you
                  give us by filling in forms on our website or by corresponding
                  with us by phone, email or otherwise or by applying for jobs
                  via our website. Information that we collect from you–
                  whenever you visit our website, we will automatically collect
                  technical information and information about your visit. This
                  is explained further below in sections 5 and 12. Information
                  we receive from other sources– We may receive personal data
                  about you from public sources such as Companies House and
                  various third parties including for example business partners,
                  sub-contractors in technical and payment services and
                  analytics providers etc. We may also collect, store and
                  process information pertaining to visitors and users of our
                  customer’s websites or services, solely for and on our
                  customers behalf. For such purposes, Indie Ridge serve and
                  shall be considered as a “Data Processor” and our customer
                  shall be the “Controller” (as both such capitalised terms are
                  defined in the European Data Protection Directive) of such
                  information and the responsibility for the security and
                  authorised usage of that information lies with our customer.
                  If you are a visitor or user of any of our customer’s websites
                  or services and have any requests or queries regarding your
                  personal information, please contact them directly.
                </p>
                <h3 class="text-xl font-bold mt-10">
                  4. What we do with your information
                </h3>
                <p>
                  We use the information held about you in the following ways:
                  <ul>
                    <li>
                      • To ensure that content from our website is presented in
                      the most effective way for you and your computer
                    </li>
                    <li>
                      • To carry out any requests or obligations arising from
                      any contact you may have with us
                    </li>
                    <li>
                      • To provide you with information or services that you
                      have requested from us or which we feel may interest you,
                      if you have given us consent to get in contact with you
                    </li>
                    <li>
                      • To enable you to participate in any interactive features
                      our service offers, as and when you choose to do so
                    </li>
                    <li>• To consider any job applicants</li>
                    <li>• To notify you of any changes to our service</li>
                  </ul>
                </p>{" "}
                <h3 class="text-xl font-bold mt-10">
                  5. Technical Information
                </h3>
                <p>
                  We may collect information about your computer, including
                  where available your IP address, operating system and browser
                  type for system administration. This is only statistical data
                  and does not identify any individual. We also collect details
                  of your visits to our website in the form of analytics, this
                  could include places and resources you access on our site,
                  time spent on our site and where you are directed to our site
                  from. Please see section 12 for more information.
                </p>
                <h3 class="text-xl font-bold mt-10">
                  6. Where we hold your information
                </h3>
                <p>
                  Our website is hosted by Gatsby and we hold your data with
                  Hubspot and Google which are contracted by use for the
                  provision of technical services. We remain responsible at all
                  times for the security of your information, but if you want to
                  know more about how we interact with Hubspot you can view
                  their privacy policy here. Google provides a lot of
                  information about how it handles personal data and the best
                  place to start is here.
                </p>
                <h3 class="text-xl font-bold mt-10">
                  7. Security of your personal data
                </h3>
                <p>
                  We take the security of your personal data very seriously and
                  have policies and procedures in place to ensure our compliance
                  with the DPA and GDPR. We limit access to your personal data
                  to those employees, agents, contractors and other third
                  parties who have a business need to know. They will only
                  process your personal data on our instructions and they are
                  subject to a duty of confidentiality. We have put in place
                  procedures to deal with any suspected personal data breach and
                  will notify you and any applicable regulator of a breach where
                  we are legally required to do so. Although we do our very best
                  to protect your personal details, transmission of information
                  via the internet is not completely secure and we can not and
                  do not guarantee the absolute security and protection of your
                  data. Any transmission is at the risk of the site user. Once
                  we have received any information we will use strict procedures
                  and security features to try and prevent unauthorised access.
                </p>
                <h3 class="text-xl font-bold mt-10">
                  8. Disclosure of your information
                </h3>
                <p>
                  We may disclose your personal information to third parties if
                  we are under a duty to disclose or share your data in order to
                  comply with any legal obligation. We may share your data with
                  our partner company Indie Ridge Limited who are based in
                  London, England. This company works alongside us on certain
                  projects. We are responsible for ensuring that our partner
                  company has an ‘adequate level of protection’ under the GDPR
                  and have put in place a strict data protection policy and
                  contractual clauses to protect any personal data we share with
                  them. We use third party selected service providers including
                  IT and system management services, legal and financial
                  advisors, billing and payment processing services, fraud
                  detection and prevention services, web analytics, marketing
                  services, recruitment services, content providers and HM
                  Revenue &amp; Customs, regulators and other authorities
                  (collectively, “Third Party Service(s)”) We may also disclose
                  your information to third parties to whom we may choose to
                  sell, transfer, or merge parts of our business or our assets.
                  Alternatively, we may seek to acquire other businesses or
                  merge with them. If a change happens to our business, then the
                  new owners may use your personal data in the same way as set
                  out in this Privacy Policy. We require all Third Party
                  Services to respect the security of your personal data and to
                  treat it in accordance with the law. We do not allow our Third
                  Party Services to use your personal data for their own
                  purposes and only permit them to process your personal data
                  for specified purposes and in accordance with our
                  instructions.
                </p>
                <h3 class="text-xl font-bold mt-10">9. Third Party links</h3>
                <p>
                  Our website may from time to time contain links to and from
                  other websites. If you were to follow a link to any of these
                  sites please note that these websites have their own privacy
                  policies and we do accept any responsibility or liability for
                  these policies. Please make sure to check these policies
                  before you submit any personal data to any of these websites.
                </p>
                <h3 class="text-xl font-bold mt-10">10. Your rights</h3>
                <p>
                  You have the right to find out about what information we hold
                  about you. If you wish to access and/or request us to make
                  corrections to the personal information that you have stored
                  with us, please contact us via email to hello@indieridge.com
                  You also have the right ‘to be forgotten’ and to have your
                  information permanently deleted from our systems. Again if you
                  would like to exercise this right then contact us at the email
                  address above. There will be no charge for reasonable
                  electronic access to your information or for your right to be
                  forgotten. You also have the right to make a complaint at any
                  time to the Information Commissioner’s Office (ICO), the UK
                  supervisory authority for data protection issues
                  (www.ico.org.uk). We would, however, appreciate the chance to
                  deal with your concerns before you approach the ICO so please
                  contact us in the first instance.
                </p>
                <h3 class="text-xl font-bold mt-10">
                  11. How long we hold your information for
                </h3>
                <p>
                  We will only retain your personal data for as long as
                  necessary to fulfil the purposes we collected it for,
                  including for the purposes of satisfying any legal,
                  accounting, or reporting requirements. To determine the
                  appropriate retention period for personal data, we consider
                  the amount, nature, and sensitivity of the personal data, the
                  potential risk of harm from unauthorised use or disclosure of
                  your personal data, the purposes for which we process your
                  personal data and whether we can achieve those purposes
                  through other means, and the applicable legal requirements.
                </p>
                <h3 class="text-xl font-bold mt-10">12. Cookies</h3>
                <p>
                  Our website uses cookies to distinguish you from other users
                  of our website. This helps us to provide you with a good
                  experience when you browse our website and also allows us to
                  improve our site.
                </p>
                <p class="font-bold">What is a cookie?</p>
                <p>
                  Cookies are small files comprised of letters and numbers that
                  are downloaded onto your desktop computer, mobile or other
                  handheld device when you access certain websites. Cookies
                  allow a website to recognise a user’s device and help your
                  browser navigate through the website by allowing you to log in
                  automatically by remembering settings you selected during
                  earlier visits, amongst other functions. Cookies do not harm
                  your computer.
                </p>
                <p class="font-bold">The use of cookies on this website</p>
                <p>
                  Cookies help us to provide you with a good experience when you
                  browse this website and allow you to make full use of the
                  features available on this website. We do not use cookies to
                  collect or record information such as your name, address or
                  personal details. We use cookies on our website for one
                  purpose, and that is to collect statistical information about
                  how visitors use our website, so we can improve the way this
                  website works and measure our success. By recording such
                  statistics as browser usage, visitor location, operating
                  system etc. we can measure and improve on how we manage and
                  maintain our site, in order to give you an ultimately better
                  visitor experience. Most web browsers automatically accept
                  cookies. Unless you have adjusted your browser settings so
                  that it will refuse cookies, cookies will be set when you
                  access our website. By continuing to use and browse this
                  website, you agree and consent to our use of cookies. If you
                  do not consent, please understand that certain features of our
                  website may not function as intended. If you would like to
                  manage your use of cookies then you can find out how to do so
                  by visiting www.allaboutcookies.org
                </p>
                <p class="font-bold">Individual cookies we use</p>
                <p>
                  <ul class="list-disc list-inside">
                    <li>
                      <span class="font-bold">Google Analytic</span>: We use
                      Google Analytics to understand general trends about our
                      content and traffic sources such as where users come from
                      and which pages are most popular. Google’s statement about
                      privacy can be found{" "}
                      <a
                        class="underline"
                        href="https://marketingplatform.google.com/about/"
                      >
                        here
                      </a>
                      .
                    </li>
                    <li>
                      <span class="font-bold">Facebook</span>: The Facebook
                      button allows you to share our platform with your friends
                      and other Facebook users. Facebook’s statement about
                      privacy relating to their Connect feature is{" "}
                      <a
                        class="underline"
                        href="https://www.facebook.com/help/?page=229348490415842"
                      >
                        here
                      </a>{" "}
                      and also{" "}
                      <a
                        class="underline"
                        href="https://www.facebook.com/privacy/policy/?entry_point=data_policy_redirect&amp;entry=0"
                      >
                        here
                      </a>
                    </li>
                    <li>
                      <span class="font-bold">Twitter</span>: This button allows
                      you to share our platform and services with your
                      followers. You can access their privacy policy in full{" "}
                      <a
                        class="underline"
                        href="https://twitter.com/en/privacy"
                      >
                        here
                      </a>
                      .
                    </li>
                    <li>
                      <span class="font-bold">LinkedIn</span>: We use this
                      button to allow you to share our platform and the services
                      we offer with your connections. LinkedIn’s information
                      about privacy can be found{" "}
                      <a
                        class="underline"
                        href="https://www.linkedin.com/legal/privacy-policy"
                      >
                        here
                      </a>
                      .
                    </li>
                  </ul>
                </p>
                <h3 class="text-xl font-bold mt-10">
                  13. Changes to Privacy Policy and your duty to inform us of
                  changes
                </h3>
                <p>
                  Any changes we make to our Privacy Policy will be displayed on
                  this page and where appropriate notified to you via email. It
                  is important that the personal data we hold about you is
                  accurate and current. Please keep us informed if your personal
                  data changes during your relationship with us.
                </p>
                <p class="font-bold mt-10">
                  Date last updated: 1st May 2022 London - England
                </p>
              </div>
            </Column>
          </Columns>
        </Section>
      </PageWrapper>
    </Layout>
  )
}

export default PrivacyPolicyPage

export const pageQuery = graphql`
  query HomePageById {
    # selecting the current post by id
    page: wpPage(id: { eq: "cG9zdDozNQ==" }) {
      # use common page fields from fragment @ src/fragments.js
      ...pageFields
      homepageSettings {
        featuredProject {
          title
        }
        intro {
          title
          content
          fieldGroupName
          link {
            ... on WpPage {
              id
              uri
            }
          }
        }
      }
    }
  }
`
